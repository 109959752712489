body {
  background-color: #bac9b4;
  font-family: Arial, sans-serif;
}

.header-component h1 {
  background: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 3em;
  width: 100%;
  margin-bottom: 0;
}

.header-component p {
  background: white;
  text-align: center;
  text-transform: lowercase;
  font-size: 1em;
  font-style: italic;
  margin-top: 0;
}

.post-list {
  padding: 0 10px;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shuffle-button {
  text-align: center !important;
  color: #317b66;
  padding: 10px !important;
  font-weight: bold;
  border-radius: 20px;
}

.post-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px 0;
  list-style: none;
  border: none;
}

.post-component p {
  margin: 0;
  padding: 4px 10px;
  background: rgb(96, 125, 139);
  color: rgb(202, 238, 255);
  font-size: 1.3em;
}

.post-component img {
  width: 100%;
  display: block;
}

.post-component button {
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
}

.app-content {
  display: flex;
  flex-direction: row;
}

.selected-item {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.selected-item p {
  display: flex;
  justify-content: center;
  font-size: 2.5em;
}

.post-component .post-name {
  font-weight: bold;
  font-size: 2em;
}

.footer-component p {
  background: #425356;
  text-align: right;
  color: rgb(202, 238, 255);
  text-transform: capitalize;
  font-size: 1em;
  font-style: italic;
  margin-bottom: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
}